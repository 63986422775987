.request-unit-spare-desktop__container{
    display: grid;
    grid-template-areas: 'brand brand model model generation generation'
                         'engine engine engine engine engine engine'
                        'list-car list-car list-car list-car list-car list-car'
                        'oem oem oem oem oem oem'
                        'oem-prepare oem-prepare oem-prepare oem-prepare oem-prepare oem-prepare'
                        'what-find what-find what-find upload upload upload';
    gap: 10px;
}

.request-unit-spare-desktop__wrap-brand{
    grid-area: brand;
}

.request-unit-spare-desktop__wrap-model{
    grid-area: model;
}
.request-unit-spare-desktop__wrap-generation{
    grid-area: generation;
}
.request-unit-spare-desktop__wrap-engine{
    grid-area: engine;
}
.request-unit-spare-desktop__wrap-list-car{
    grid-area: list-car;
    &>div{
        width: 100%;
    }
}


.request-unit-spare-desktop__wrap-text{
    grid-area: what-find;
}
.request-unit-spare-desktop__wrap-oem{
    grid-area: oem;
}
.request-unit-spare-desktop__wrap-oem-list-prepare{
    grid-area: oem-prepare;
}
.request-unit-spare-desktop__wrap-upload-images{
    grid-area: upload;
    top: 20px;
}
// -----------------------------------------------------------
.request-repair-desktop__container{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: auto auto;
    grid-template-areas: 
                        'country country city city . .'
                        'what-find what-find what-find upload upload upload';
    gap: 10px;
}

.request-repair-desktop__wrap-country{
    grid-area: country;
}

.request-repair-desktop__wrap-city{
    grid-area: city;
}

.request-repair-desktop__wrap-text{
    grid-area: what-find;
}

.request-repair-desktop__wrap-upload-images{
    grid-area: upload;
    top: 20px;
}
// -----------------------------------------------------------
.request-truck-desktop__container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: 'what-find upload';
    gap: 10px;
}

.request-truck-desktop__wrap-country{
    grid-area: country;
}

.request-truck-desktop__wrap-city{
    grid-area: city;
}

.request-truck-desktop__wrap-text{
    grid-area: what-find;
}

.request-truck-desktop__wrap-upload-images{
    grid-area: upload;
    margin-top: 37px;
}