.text {
  font-family: var(--text-font);
  color: var(--text-color);
  line-height: 1.4;
  // margin: 0 0 15px 0;
  // display: inline-block;
  //text__main-desktop-title
  &__main-desktop{
    &-title{
      font-weight: 700;
    }
    //text__main-desktop-faq-title-item
    &-faq-title-item{
      position: relative;
      top: 2px;
    }
  }
  //text__request-desktop-menu-item-title
  &__request-desktop-menu-item-title{
    font-weight: 700;
  }
}