.offset{
    margin: 0 auto;
    max-width: 450px;
    // position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    min-height: fit-content;

    font-family: "HelveticaNeue";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
    // height: 100%; нельзя ломается
}
.position{
    &-center{
        justify-content: center;
        & > div{
            text-align: center;
        }
    }
}
.mt {
    &--10{
        padding-top: 10px;
    }
    &--13{
        padding-top: 13px;
    }
    &--20{
        padding-top: 20px;
    }
    &--30{
        padding-top: 30px;
    }
    &--40{
        padding-top: 40px;
    }
    &--50{
        padding-top: 50px;
    }
    &--60{
        padding-top: 60px;
    }
    &--90 {
        padding-top: 90px;
    }
}
.mb {
    &--10{
        padding-bottom: 10px;
    }
    &--15{
        padding-bottom: 15px;
    }
    &--20{
        padding-bottom: 20px;
    }
    &--25{
        padding-bottom: 25px;
    }
    &--30{
        padding-bottom: 30px;
    }
    &--35{
        padding-bottom: 35px;
    }
    &--40{
        padding-bottom: 40px;
    }
    &--50{
        padding-bottom: 50px;
    }
    &--60{
        padding-bottom: 60px;
    }
    &--90 {
        padding-bottom: 90px;
    }
}

.mr {
    &--5{
        padding-right: 5px;
    }
    &--10{
        padding-right: 10px;
    }
    &--15{
        padding-right: 15px;
    }
    &--20{
        padding-right: 20px;
    }
    &--25{
        padding-right: 25px;
    }
    &--30 {
        padding-right: 30px;
    }
}
.ml {
    &--5{
        padding-left: 5px;
    }
    &--10{
        padding-left: 10px;
    }
    &--15{
        padding-left: 15px;
    }
    &--20{
        padding-left: 20px;
    }
    &--25{
        padding-left: 25px;
    }
    &--30 {
        padding-left: 30px;
    }
}