.swiper-pagination-bullet-active{
  background-color: var(--background-color-block) !important;
  width: 8px !important;
  border-radius: 12px !important;
}

.image-one-card__swiper-pagination-bullet-active{
  background-color: var(--background-color-block) !important;
  width: 8px !important;
  border-radius: 12px !important;
}

.image-one-card__swiper-pagination-bullet{
  background-color: var(--background-color-block) !important;
  width: 4px !important;
  border-radius: 50% !important;
}