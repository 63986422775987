.cardmp{
  //.cardmp__container{}
  &__container{
    display: flex;
    flex-direction: column;
    line-height: 180%;
    position: relative;
    min-width: 170px;
    &:hover{
      cursor: pointer;
    }
  }
  //.cardmp__info-container-about-user{}
  &__info-container-about-user{
    display: flex;
    flex-direction: row;  
  }
  //.cardmp__control-container{}
  &__control-container{
    display: flex;
    flex-direction: row;
    font-size: 12px;
    font-family: "HelveticaNeue";
    color: var(--text-color);
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  //.cardmp__container-opacity{}
  &__container-opacity{
    display: flex;
    font-size: 12px;
    flex-direction: column;
    line-height: 180%;
    color: var(--text-color-opacity);
  }
  //.cardmp__container-border{}
  &__container-border{
    display: flex;
    // padding: 5px;
    flex-direction: column;
    border: 1px solid var(--border-color);
    line-height: 180%;
    // background-color: var(--background-color);
    border-radius: 10px;
    padding: 20px;
  }
  //.cardmp__galarey{}
  &__galarey{
    padding: 0;
    border-radius: 10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  //.cardmp__title{}
  &__title{
    padding: 0;
    color: var(--text-color);
    font-family: "HelveticaNeue";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; 
  }
  //.cardmp__text{}
  &__text{
    padding: 0;
    color: var(--text-color);
    font-family: "HelveticaNeue";
    font-size: 12px;
    line-height: 140%;
  }
    //.cardmp__price-catalog{}
  &__price-catalog{
    padding: 0;
    color: var(--text-color);
    font-size: 16px;
    font-family: "HelveticaNeue";
    word-spacing: 1.5px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    text-overflow: ellipsis;
    margin-bottom: 8px;
    &>span{
      padding: 0;
      color: var(--text-color);
      font-size: 14.7px;
      font-family: "HelveticaNeue";
      word-spacing: 1.5px;
      font-style: normal;
      font-weight: 700;
      line-height: 18px;
      text-overflow: ellipsis;
    }
  }
  //.cardmp__price{}
  &__price{
    padding: 0;
    color: var(--text-color);
    font-size: 16px;
    font-family: "HelveticaNeue";
    font-weight: 500;
    word-spacing: 1.5px;
    letter-spacing: 0px;
  }
  //.cardmp__location-catalog{}
  &__location-catalog{
    padding: 0;
    color: var(--text-color-opacity);
    display: flex;
    font-size: 12px;
    font-family: "HelveticaNeue";
    font-style: normal;
    font-weight: 400;
    line-height: 17px; 
    min-height: 25px;
  }
  //.cardmp__location{}
  &__location{
  padding: 0;
    color: var(--text-color);
    display: flex;
    font-size: 12px;
    font-family: "HelveticaNeue";
  }
  //.cardmp__time{}
  &__time{
    padding: 0;
    // color: var(--text-color);
    // opacity: .2;
    font-size: 12px;
    font-family: "HelveticaNeue";
  }
  //.cardmp__descriptions{}
  &__descriptions{
    padding: 0;
    color: var(--text-color);
    font-size: 14px;
    font-family: "HelveticaNeue";
    // max-height: 70px;
    overflow: hidden;
    line-height: 140%;
    margin-bottom: 10px;
    margin-top: 24px;
    &>span{
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;  
      overflow: hidden;
    }
  }
  //.cardmp__descriptions-title{}
  &__descriptions-title{
    padding: 0;
    color: var(--text-color);
    font-size: 14px;
    font-family: "HelveticaNeue";
    font-weight: 500;
    word-spacing: 1.5px;
    letter-spacing: 0px;
  }

  //.cardmp__user-container{}
  &__user-container{
    display: flex;
    flex-direction: column;

  }
  //.cardmp__user-link{}
  &__user-link{
    color: var(--text-color-link);
  }
  //.cardmp__user{}
  &__user{
    padding: 0;
    color: var(--text-color);
    font-size: 12px;
    font-family: "HelveticaNeue";
    display: flex;
    &>span{
      margin-left: 5px;
    }
  }


  //.cardmp__characteristic{}
  &__characteristic{
    padding: 0;
    color: var(--text-color);
    font-size: 12px;
    font-family: "HelveticaNeue";
  }
  //.cardmp__characteristic-title{}
  &__characteristic-title{
    padding: 0;
    color: var(--text-color);
    font-size: 14px;
    font-family: "HelveticaNeue";
    font-weight: 500;
    word-spacing: 1.5px;
    letter-spacing: 0px;
  }
  //.cardmp__characteristic-sub-title-container{}
  &__characteristic-sub-title-container{
    display: flex;
    margin-top: 14px;
  }
  //.cardmp__characteristic-sub-title{}
  &__characteristic-sub-title{
    font-size: 14px;
    font-family: "HelveticaNeue";
    font-weight: 400;
    word-spacing: 1.5px;
    letter-spacing: 0px;
    margin-right: 10px;
  }
  //.cardmp__favorite{}
  &__favorite{
    padding: 0;
    position: absolute !important;
    right: 16px !important;
    top: 14px !important;
    width: 26px !important;
    height: 26px !important;
    // background-color: var(--background-color);
    border-radius: 6px !important;
    z-index: var(--z-index-element-link);
    &:hover{
      cursor: pointer;
    }
  }
  //.cardmp__favorite-fone{}
  &__favorite-fone{
    width: 24px;
    height: 24px;
    position: absolute;
    // background-color: var(--background-color);
    z-index: 4;
    right: 13px;
    top: 25px;
    z-index: 4;
    border-radius: 7px;
  }
  &__bound-fone{
    width: 30px;
    height: 30px;
    position: absolute;
    // background-color: var(--background-color);
    z-index: 4;
    transform: translate(-4px,-2px);
    border-radius: 7px;  
  }
  //.cardmp__bound-container{}
  &__bound-container{
    position: absolute;
    bottom: 25px;
    margin: 0 auto;
  }
  //.cardmp__bound-text{}
  &__bound-text{
    color: var(--text-color);
    position: relative;
    z-index: 5;
  }
}