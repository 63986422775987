.card-review{
  //.card-review__container{}
  &__container{
    padding: 20px;

    &:not(:last-child){
      margin-bottom: 14px;
    }
  }
  //.card-review__statistiv-review{}
  &__statistiv-review{
    padding: 0;
  }
}

.feedback-reviews{
  //.feedback-reviews__container{}
  &__container{
  }
  //.feedback-reviews__button{}
  &__button {
    width: 100%;
    height: 35px;
    background-color: var(--background-color-button-red);
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--text-color-white);    
  }
  //.feedback-reviews__form-input{}
  &__form-input {
    width: 100%;
    border: none;
    min-height: 50px;
    &>textarea{
      padding-left: 0;
    }
  }
}