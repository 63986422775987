// @font-face {
//     font-family: "HelveticaNeue";
//     // src: url("./HelveticaNeue-Light.woff2") format("woff2");
//     src: url("./HelveticaNeueCyr-Roman.woff2") format("woff2");
//     // src: url("./HelveticaNeue Regular.ttf") format("ttf");
//     font-style: normal;
//     font-weight: 400;
// }
//                                                                                     // @font-face {
//                                                                                     //     font-family: "HelveticaNeue";
//                                                                                     //     src: url("./helveticaneue.woff2") format("woff2");
//                                                                                     //     font-style: normal;
//                                                                                     //     font-weight: 400;
//                                                                                     // }

// @font-face {
//     font-family: "HelveticaNeue";
//     src: url("./HelveticaNeueCyr-Medium.woff2") format("woff2");
//     // src: url("./HelveticaNeueCyr-Roman (1).woff2") format("woff2");
//     // src: url("./HelveticaNeueHv.ttf") format("ttf");
//     font-style: normal;
//     font-weight: 500;
// }

//                                                                                     // @font-face {
//                                                                                     //     font-family: "HelveticaNeue";
//                                                                                     //     src: url("./helveticaneue-medium.woff2") format("woff2");
//                                                                                     //     font-style: normal;
//                                                                                     //     font-weight: 500;
//                                                                                     // }

// @font-face {
//     font-family: "HelveticaNeue";
//     src: url("./HelveticaNeueCyr-Bold.woff2") format("woff2");
//     // src: url("./HelveticaNeue Bold.ttf") format("ttf");
//     font-style: normal;
//     font-weight: 700;
// }
//                                                                                     // @font-face {
//                                                                                     //     font-family: "HelveticaNeue";
//                                                                                     //     src: url("./helveticaneue-bold.woff2") format("woff2");
//                                                                                     //     font-style: normal;
//                                                                                     //     font-weight: 700;
//                                                                                     // }

@font-face {
    font-family: 'HelveticaNeue';
    src: local('Helvetica Neue Roman'), local('Helvetica-Neue-Roman'),
        url('./1/HelveticaNeueCyr-Roman.woff2') format('woff2'),
        url('./1/HelveticaNeueCyr-Roman.woff') format('woff'),
        url('./1/HelveticaNeueCyr-Roman.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'HelveticaNeue';
    src: local('Helvetica Neue Medium'), local('Helvetica-Neue-Medium'),
        url('./1/HelveticaNeueCyr-Medium.woff2') format('woff2'),
        url('./1/HelveticaNeueCyr-Medium.woff') format('woff'),
        url('./1/HelveticaNeueCyr-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'HelveticaNeue';
    src: local('Helvetica Neue Bold'), local('Helvetica-Neue-Bold'),
        url('./1/HelveticaNeueCyr-Bold.woff2') format('woff2'),
        url('./1/HelveticaNeueCyr-Bold.woff') format('woff'),
        url('./1/HelveticaNeueCyr-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}