.block{
  &__container{
    //.block__container-inner-block{}
    &-inner-block{
      padding: 0;
      position: relative;
    }
    //.block__container-block{}
    &-block{
      padding: 0px;
      width: 100%;

    }
    //.block__container-block--border{}
    &-block--border{
      padding: 15px 20px;      
      border-radius: 10px;
      position: relative;
      justify-content: start;
      align-items: baseline;
    }
    //.block__container--two-column{}
    &--two-column{
      display: grid;
      align-items: center;
      grid-template-columns: 1fr 1fr;
      position: relative;
    }
    //.block__container--icon{}
    &--icon{
      position: relative;
    }
    //.block__container--row-grid{}
    &--row-grid{
      position: relative;
      display: grid;
      grid-auto-rows: auto;
      gap: 3px;
      letter-spacing: 0px;
      filter: blur(var(--filter-blur))
    }
  }
  //.block__title{}
  &__title{
    font-size: 14px;
    font-weight: 400;
    font-family: 'HelveticaNeue';
    color: var(--text-color);
    letter-spacing: 0px;
    overflow:hidden;
    display:inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;    
    line-height: 22px;
    //.block__title-desc{}
    &-desc{
      padding: 0;
      font-size: 13px;
      font-family: 'HelveticaNeue';
      letter-spacing: 0px;
      font-weight: 500;
      color: var(--text-color-opacity);
    }
  }
  //block__flex
  &__flex{
    display: flex;
    position: relative;
  }
  //block__grid
  &__grid{
    display: grid;
    position: relative;
    grid-auto-rows: max-content;
  }

  //addClass
  &__layout{
    &-container{
      //block__layout-container
      grid-template-columns: 20% 80%;
    }
    //block__layout-column-sidebar
    &-column-sidebar{
      padding: 10px;
      grid-auto-rows: max-content;
    }
    //block__layout-column-main
    &-column-main{
      padding: 10px;
      border-radius: 16px;
      align-items: start;
      margin-left: 10px;
    }
  }
  //block__menu-desktop-container
  &__menu-desktop{
    &-container{
      flex-direction: column;
      gap: 10px;
    } 
    //block__menu-desktop-sidebar-button
    &-sidebar-button{
      bottom: 10%;
      position: fixed;
    }
    //block__menu-desktop-item
    &-item{
      display: flex;
      gap: 10px;
      align-items: center;
      font-size: 14px;
      font-family: 'HelveticaNeue';
      letter-spacing: 0px;
      color: var(--text-color);
      padding: 10px  14px;
      &:hover{
        cursor: pointer;
      }
      
      //block__menu-desktop-item--active
      &--active{
        display: flex;
        padding: 10px 14px;
        border-radius: 9px;
        gap: 10px;
        align-items: center;
        font-size: 14px;
        font-family: 'HelveticaNeue';
        letter-spacing: 0px;
        color: var(--text-color);
        background-color: var(--text-color-white);
        &:hover{
          // cursor: not-allowed;
        }
      }
    }
  }
  //block__logo-container
  &__logo-container{
    align-items: center;
    justify-content: center;
    padding: 29px 10px;
  }
  //block__arrowcameback-container
  &__arrowcameback-container{
    &:hover{
      cursor: pointer;
    }
  }
  //block__title-container-section
  &__title-container-section{
    align-items: center;
    padding: 20px;
    font-family: 'HelveticaNeue';
    letter-spacing: 0px;
    line-height: 140%;
    color: var(--text-color);
    font-weight: 700;
  }
  
  //block__header-container
  &__header{
    &-container{
      background-color: var(--background-color-block);
      padding: 18px 20px;
      border-radius: 16px;
      max-height: 72px;
    }
    //block__header-left
    &-left{
      flex: 1 1 ;
      justify-content: flex-start;
    }
    //block__header-right
    &-right{
      flex: 1 1 ;
      justify-content: flex-end;
      display: flex;
      flex-direction: row;
      //block__header-right-action-container
      &-action-container{
        gap: 10px;
        align-items: center;
        margin-left: 10px;
      }
    }
  }
  
  //block__main-desktop-container
  &__main-desktop{
    &-container{
      flex-direction: column;
      background-color: var(--background-color-block);
      margin-top: 14px;
      border-radius: 16px;
      padding: 20px;
      position: relative;
      height: calc(100vh - 100px);
      overflow-y: scroll;
      //block__main-desktop-container-catalog
      &-catalog{
        grid-template-columns: repeat(5, 1fr);
        // grid-template-columns: repeat(5, minmax(150px, 250px));
        // grid-template-rows: repeat(3, minmax(400px, 600px));
        gap: 10px;
        position: relative;
      }
      //block__main-desktop-container-filters
      &-filters{
        gap: 10px;
        display: flex;
        align-items: center;
        &>div{
          flex: 1 1;
        }
      }
      //block__main-desktop-container-faq
      &-faq{
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        flex-direction: column;
        background-color: var(--background-color-block);
        position: relative;
        //block__main-desktop-container-faq-item
        &-item{
          background-color: var(--border-color);
          flex-direction: column;
          padding: 10px;
          border-radius: 10px;
          height: max-content;
        }
      }
      // block__main-desktop-container-params
      &-params{
        grid-template-columns: repeat(7,1fr);
        gap: 5px;
        //block__main-desktop-container-params-title
        &-title{
          font-size: 14px;
          font-weight: 500;
          font-family: 'HelveticaNeue';
          letter-spacing: 0px;
          color: var(--text-color);
          justify-content: space-between;
        }
        // block__main-desktop-container-params-controller
        &-controller{
          grid-template-columns: minmax(80px, 100px) minmax(80px, 100px);          
        }
        //block__main-desktop-container-params-item-location
        &-item{
          &-location{
            display: flex;
          }
        }
      }
    }
  }
  &__requests-desktop{
    //block__requests-desktop-container-item
    &-container-item{
      display: flex;
      flex-direction: column;
      background-color: var(--background-color-block);
      padding: 20px;
      overflow: hidden;
      height: min-content;
      border-radius: 16px;
      // width: 100%;
    }
    
    &-menu{
      //block__requests-desktop-menu-container
      &-container{
          overflow: auto;
          height: calc(100vh - 72px);
          grid-template-columns: 1fr;
          // grid-template-rows: repeat(5, auto);
          margin-top: 15px;
          gap: 10px;
        }
        //block__requests-desktop-menu-item-container
        &-item-container{
          justify-content: space-between;
          // padding: 20px;
          width: 100%;
          &>div {
            filter: invert(36%) sepia(25%) saturate(6488%) hue-rotate(343deg) brightness(91%) contrast(88%);
          }
          &--active{
            justify-content: space-between;
            background-color: var(--background-color-block);
            &>div{
              transform: rotate(-90deg);
              filter: hue-rotate(45deg);
            }
            &:hover{
              cursor: pointer;
            }
          }
          &:hover{
            cursor: pointer;
          }
        }
        //block__requests-desktop-menu-item-sub-container
        &-item-sub-container{
          position: relative;          
        }
      }
    }
    //block__fake-options
    &__fake-options{
      border: 1px solid var(--border-color);
      border-radius: 7px;
      padding: 10px;
      &::after{
        content: "";
        position: absolute;
        top: 0;
        right: 40px;
        width: 1px;
        height: 50%;
        top: 50%;
        transform: translateY(-50%);
        padding-top: 3px;
        padding-bottom: 5px;
        background-color: var(--border-color);
        z-index: -1;
      }
      &::before{
        content: "";
        position: absolute;
        right: 15px;  
        width: 8px;
        height: 8px;
        background-color: transparent;      
        top: 25%;
        transform: rotate(135deg) translateY(-25%);
        border-top: 2px solid hsl(0, 0%, 40%);        
        border-right: 2px solid hsl(0, 0%, 40%);
        z-index: -1;
      }
    }
}

.profile-desktop__self-info{
  padding: 32px;
  border-radius: 16px;
  background-color: var(--background-color-block);
}