
.icon{
    //.icon__image{}
    &__image{
        padding: 0;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        position: relative;
    }
}