.h{
    &1,&2,&3,&4,&5,&6{
        font-family: var(--title-font);
        color: var(--title-color);
        line-height: 1.4;
        // margin: 0 0 15px 0;
        // display: inline-block;
    }
    &1{
        font-size: 40px;
    }
    &2{
        font-size: 35px;
    }
    &3{
        font-size: 30px;
    }
    &4{
        font-size: 25px;
    }
    &5{
        font-size: 20px;
    }
    &6{
        font-size: 15px;
    }

}