.modal {

    ///modal__container
    &__container {
        position: fixed;
        width: 77%;
        height: 100%;
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 999999;
        left: 23vw;
        transition: top .3s;
        transform: translateY(-50%) scale(.8);
        border-radius: 14px;
        box-shadow: 1px 1px 5px #000;
        padding: 15px;
    }
    //modal__context
    &__context{
        font-size: 16px;
        font-weight: 700;
        font-family: 'HelveticaNeue';
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        width: 100%;
        line-height: 160%;
        padding: 50px 20px;
    }
    //modal__control-panel
    &__control-panel {
        width: 100%;

        //modal__control-panel-container
        &-container {
            // flex: 1;
            // flex-direction: row;
            // gap: 10;
            // align-self: "baseline";
            // align-items: "center";
            // display: flex;
            display: grid;
            width: 100%;
            gap: 15px;
        }
        //modal__control-panel-btn
        &-btn {
            padding: 19px 10px;
            min-width: 100%;
            min-height: 48px;
            max-height: 48px;
            border: 1px solid var(--border-color);
            border-radius: 12px;
            background-color: var(--background-color-Mirage-blue);
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--text-color-white);
            
            //modal__control-panel-btn-text-cancel
            &-text-cancel {
                color: var(--text-color-white);
                font-size: 12px;
                font-weight: 700;
                font-family: 'HelveticaNeue';
                display: flex;
                text-align: center;
                align-items: center;
                justify-self: center;
            }
            
        }
    }
}