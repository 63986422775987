.pagination{
  &__container{
    //.pagination__container-wrap{}
    &-wrap{
      padding: 0;
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    //.pagination__container-items{}
    &-items{
      display: grid;
      overflow: hidden;
      grid-auto-flow: column;
      gap: 4px;
    }
  }
  //.pagination__item{}
  &__item{
    width: 32px;
    height: 32px;
    min-width: 32px;
    border-radius: 8px;
    border: 1px solid var(--background-color-light-roze-border);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--text-color);
    font-weight: 500;
    font-family: 'HelveticaNeue';
    font-size: 10px;
    &:hover{
      cursor: pointer;
    }

  }
}