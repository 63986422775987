.preloader {

  //.preloader__container-background-preload{}
  &__container-background-preload {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: #34313140;
    z-index: 99999;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}


.preload__wrap-container {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 200%;
  position: relative;
  width: 100%;
  flex-direction: column;
  margin-top: 50px;
}


.body-container-preload {
  display: flex;
}


.preloader-container {
  height: 100%;
  margin: 0;
  background: transparent;
  color: #666;
  font-family: open sans;
  padding: 0;
  position: relative;
}

/* Containers */



.preloader {
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;
  width: 70%;
  // margin: 5%;
  // border: 1px solid #ddd;
  position: relative;
}


.preloader-container {
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}


.preloader-dots {
  width: 50px;
}

.preloader-dots .dot {
  display: inline-block;
  background: #ff0000;
  height: 4px;
  width: 4px;
  // opacity: 0.3;
  margin-right: 3px;
  border-radius: 50%;
  animation: moveit 1.8s infinite;
}

.dot:nth-child(2) {
  animation-delay: .15s;
}

.dot:nth-child(3) {
  animation-delay: .3s;
}

.dot:nth-child(4) {
  animation-delay: .45s;
}

.dot:nth-child(5) {
  animation-delay: .6s;
}

@keyframes moveit {
  0% {
    opacity: 0;
    transform: translateY(0px);
  }

  35% {
    transform: translateY(0px);
    opacity: 0.3;
  }

  50% {
    transform: translateY(-20px);
    opacity: 0.8;
  }

  70% {
    transform: translateY(3px);
    opacity: 0.8;
  }

  85% {
    transform: translateY(-3px);
  }
}