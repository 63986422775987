.spinner{
    //.spinner__container{}
    &__container{
        width: 100%;
        height: 100%;
        position: fixed;
        z-index: 999999999;
        background-color: rgb(0, 0, 0, .4);
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: none;
        top: 0;
        left: 0;
        &>div{
            animation: rotate-gear 3s infinite linear;
        }
    }
}

@keyframes rotate-gear {
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}