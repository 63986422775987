.select__btn {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    user-select: none;
    scrollbar-width: none;
    // overflow: auto hidden;
    white-space: nowrap;
    padding: 15px;
    background-color: transparent;
    // border: 1px solid $pi2;  
    border-radius: 3px;
    padding-right: 30px;
}