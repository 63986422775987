.profile{
    //profile__info-user
    &__info-user{
        display: grid;
        gap: 10px;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        grid-template-areas: 'user link city-phpone . .'
                             'menu menu menu menu menu';
        background-color: aliceblue;
    }
    // profile__user
    &__user{
        grid-area: user
    }
    // profile__menu
    &__menu{
        grid-area: menu
    }
    // profile__link
    &__link{
        grid-area: link
    }


    // profile__city-phone
    &__city{
        grid-area: city-phone;
    }
    // profile__phone
    &__phone{
        grid-area: phone
    }
}