.oem{
    //.oem__list-container{}
    &__list-container{
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        max-width: fit-content;
    }
    //.oem__container{}
    &__container{
        padding: 0;
        // width: 71px;
        // height: 71px;
        // overflow: hidden;
        margin-right: 10px;
    }
}