.main-menu{
  //.main-menu__container-wrap{}
  &__container-wrap{
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
    min-width: calc((var(--max-width-mobile, 320px)) / 2);
  }
  //.main-menu__container{}
  &__container{
    display: grid;
    grid-auto-flow: column;
    gap: 10px;
    width: 100%;
    max-width: calc(var(--max-width-mobile, 320px));
    height: calc( 136px + var(--hight-scrollbar) );
    // background-color: var(--background-color);
    border-radius: 14px;
    overflow-x: auto;
    // overflow-y: hidden;
    &:not(:last-child){
      gap: 12px;
    }    
    &::-webkit-scrollbar {
      // display: none;
       display: block;
      width: .0em;
      height: var(--hight-scrollbar);
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      border-radius: 10px;
    }
    
    &::-webkit-scrollbar-button {
        background: var(--background-color-opacity-50);
    }
    &::-webkit-scrollbar-track-piece {
        background: var(--background-color-opacity-50);
    }
    &::-webkit-scrollbar-thumb {
        background: #d5d4d4; 
    }
  }
  &__item{
    //.main-menu__item-container{}
    &-container{
      // width: 128px;
      min-width: 140px;
      width: 100%;
      height: 128px;
      border-radius: 14px;
      background-color: var(--bgc-fair-pink);
      position: relative;
      // max-width: calc((var(--max-width-mobile, 320px)) / 2);
      //.main-menu__item-container--disabled{}
      &--disabled{
        width: 128px;
        height: 128px;
        border-radius: 14px;
        background-color: var(--bgc-fair-pink);
        // opacity: .5;
        position: relative;        
      }
      &:hover{
        cursor: pointer;
      }
    }
    //.main-menu__item-title{}
    &-title{
      position: relative;
      max-width: 74%;
      font-size: 14px;
      color: var(--text-color);
      font-family: 'HelveticaNeue';
      font-style: normal;
      font-weight: 700;
      line-height: 110%; 
      letter-spacing: 0px;
    }
    //.main-menu__item-container-icons{}
    &-container-icons{
      position: absolute;
      display: flex;
      left: 20px;
      bottom: 13px;
      // width: 100%;
    }
    //.main-menu__item-icon-container{}
    &-icon-container{
      background-color: var(--background-color-icon-red);
      width: 42px;
      height: 42px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      // top: -4px;
      // left: 5px;
      &:not(:first-child){
        margin-left: 15px;
        left: 0;
      }
    }
  }
}
