.navigation{
  //.navigation__my-application-container{}
  &__my-application{
    &-container{
      display: grid;
      grid-template-columns: auto auto ;//auto
      grid-template-rows: auto auto;
      grid-template-areas:  'unit unit unit spare spare'
                            'truck truck transport transport transport';
      gap: 10px;
      
    }
                        }
                        // 'truck transport transport respair';
  //.navigation__container{}
  &__container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;      
    gap: 10px;
  }
}