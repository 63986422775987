.mp-filter{
    
    // mp-filter__control
    &__control{
        border-radius: 7px !important;
        min-height: 36px !important;
        max-height: 36px !important;
        // mp-filter__control--is-disabled
        &--is-disabled{
            background-color: var(--border-color-line) !important;
        }
    }
    // mp-filter__control--is-focused
    // mp-filter__menu
    &__control--is-focused, &__menu{
        max-width: calc(100% - 75px);
    }
    // mp-filter__input-container
    &__input-container{
        padding: 0;
    }

    // mp-filter__placeholder
    &__placeholder{
        // height: 38px;
        // line-height: 38px;
    }

    // mp-filter__value-container
    &__value-container{
        max-height: 24px;
    }
}

.mp-desktop-filter {

    // mp-desktop-filter__control
    &__control {
        border-radius: 7px !important;
        min-height: 45px !important;
        max-height: 45px !important;
        width: 100%;
        // mp-desktop-filter__control--is-disabled
        &--is-disabled {
            background-color: var(--border-color-line) !important;
        }
    }

    // mp-desktop-filter__control--is-focused
    // mp-desktop-filter__menu
    &__control--is-focused,
    &__menu {
        border-color: rgb(124, 114, 114) !important;
        box-shadow: 0 0 0 .1px rgb(124, 114, 114) !important;
        z-index: 999 !important;
        // max-width: calc(100% - 75px);
    }

    // mp-desktop-filter__input-container
    &__input-container {
        padding: 0;
    }

    // mp-desktop-filter__placeholder
    &__placeholder {
        // height: 38px;
        // line-height: 38px;
    }

    // mp-desktop-filter__value-container
    &__value-container {
        max-height: 24px;
    }
}

.request {

    // request__control
    &__control {
        border-radius: 7px !important;
        min-height: 45px !important;
        max-height: 45px !important;
        width: 100%;
        // request__control--is-disabled
        &--is-disabled {
            background-color: var(--border-color-line) !important;
        }
    }

    // request__control--is-focused
    // request__menu
    &__control--is-focused,
    &__menu {
        border-color: rgb(124, 114, 114) !important;
        box-shadow: 0 0 0 .1px rgb(124, 114, 114) !important;
        z-index: 999 !important;
        // max-width: calc(100% - 75px);
        //request__menu-list
        &-list{
            max-height: 250px !important;
        }
    }

    // request__input-container
    &__input-container {
        padding: 0;
    }

    // request__placeholder
    &__placeholder {
        // height: 38px;
        // line-height: 38px;
    }

    // request__value-container
    &__value-container {
        max-height: 24px;
    }
}