.widget{
  //.widget__container{}
  &__container{
    padding: 0;
    position: absolute;
    color: var(--text-color);
    right: -3px;
    top: -8px;
    width: 15px;
    height: 15px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    &::after{
      content: '';
      position: absolute; 
      width: 100%;
      height: 100%;
      background-color: var(--border-color);
      border-radius: 50%;
      z-index: -1;
      left: 0;
      top: -1px;
    }
  }
}