.upload{
  //.upload__add-files{}
  &__add-files{
    display: flex;
    // padding: 32px 16px;
    flex-direction: column;
    justify-content: center;
    &>div>div{
      border-radius: 10px;
      border: 1px solid var(--border-color);
      margin-bottom: 12px;
      &>div>label{
        display: flex;
        flex-direction: column;
        text-decoration-line: none;
        &>div{
          width: 24px !important;
          height: 24px !important;
        }
      }
    }
  }
}