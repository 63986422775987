.select {
  //select__select{}
  &__select{
    padding: 0 15px;
    margin: 10px 2px;
    order: 1px solid #787878;
    border-radius: 3px;
    min-width: 200px;
    min-height: 40px;
    z-index: 99;
    pointer-events: all;
    width: calc(100% - 10px);
    background-color: var(--background-color-block);
    border: 1px solid var(--border-color);
    box-shadow: 0px 4px 14px var(--box-shadow);
    border-radius: 8px;
    transition: visibility .3s ease-in-out .2s;
    //.select__select--active{}
    &--active{
      pointer-events: none;
      background-color: #48484878;
    }
  }
  //.select__disable{}
  &__disable{
    padding: 0;
    opacity: .2;
    transition: opacity .05s ease;
    pointer-events: none !important;
    & + div{
      opacity: .2;
    }
  }
  //.select__container{}
  &__container {
    display: flex;
    width: max-content;
    position: relative;
    min-height: 48px;
    max-height: 48px;
    width: 100%;
    flex-direction: column;
    &>:nth-child(4){
      top: 22px;
    }
  }
  //.select__feedback{}
  &__feedback{
    padding: 0;
    ~div{
      top: 30px; 
    }
  }
  //.select__drop-down{}
  &__drop-down{
    padding: 0;
  }
  //.select__dropdown-list-payment{}
  &__dropdown-list-payment{
    border-radius: 8px !important;
    padding: 0;
    background-color: var(--background-color-block);
  }
  //.select__label{}
  &__label{
    font-size: 13px;
    margin: 0 0 3px 10px;
  }
  //.select__body{}
  &__body {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap;
    border-radius: 3px;
    min-width: 200px;
    min-height: 48px;
    z-index: 99;
    pointer-events: all;
    width: 100%;
    background-color: var(--background-color-block);
    border: 1px solid var(--border-color);
    box-shadow: 0px 4px 14px var(--box-shadow);
    border-radius: 8px;
    color: var(--text-color);
    transition: visibility .3s ease-in-out .2s;
    
    &>:nth-child(1){
      margin-left: 5px;
      pointer-events: none;
      padding: 6px 0px 6px 6px;
    }

    &>:nth-child(2){
      pointer-events: none;
      padding: 6px 0px 6px 6px;
    }

    &:hover{
      border: 1px solid;
      cursor: pointer;
    }
    &--active{
      border: 1px solid var(--border-color) !important;
      pointer-events: none;
      border-radius: 8px 8px 0 0 !important;;
    }
  }

  //.select__icon{}
  &__icon {
    pointer-events: none;
    transform: rotate(0);
    transition-duration: .5s;
    position: absolute;
    right: 10px;
    top: 50%;
    z-index: 100;
    //.select__icon--active{}
    transform: translate(-15px, -30%) rotate(0deg);
    &--active{
      transition-duration: .5s;
      transform: translate(-15px, -30%) rotate(180deg);
    }
  }
  
  //.select__option-container{}
  &__option-container {
      opacity: 0;
      visibility: hidden;
      width: 100%;
      // transition-duration: .2s;
      & > span {
        opacity: 0;
        visibility: hidden;
        display: none;
      }
    //.select__option-container--active{}
    &--active {
      transition-duration: .5s;
      visibility: visible;
      opacity: 1;
      color: #000;
      width: 100%;
      height: max-content;
      position: absolute;
      top: 46px;
      padding-top: 10px;
      padding-bottom: 10px;
      z-index: 9999;
      left: 0;
      background-color: var(--background-color-block);
      // border: 1px solid var(--border-color) !important;
      box-shadow: 0px 4px 14px var(--box-shadow);
      border-radius: 0 0 8px 8px;
      min-width: 200px;
      max-height: 300px;
      overflow: auto;
      display: block;//grid;
      pointer-events: all;
      // @include scrollbar;
      & > span {
        opacity: 1;
        visibility: visible;
        display: flex;
        color: var(--text-color);
        background-color: var(--background-color-block);
        box-shadow: 0px 4px 14px var(--box-shadow);
        border-radius: 8px;
        &:hover{
          background-color: var(--bgc-fair-pink);
        }
      }
    }
  }
  //.select__dropdown-list-car{}
  &__dropdown-list-car{
    background-color: var(--background-color-block);
    border: 1px solid var(--border-color);
    box-shadow: 0px 4px 14px var(--box-shadow);
    border-radius: 8px;
  }
  //.select__option-item{}
  &__option-item{
    padding: 0 10px;
    height: 45px;
    display: flex;
    align-items: center;
    background: transparent;
    border-radius: 6px;
    margin: 0 10px 6px;
    width: calc(100% - 20px);
    &>div{
      margin-right: 15px !important;
      pointer-events: none;
      position: relative;
      &>span{
        transform: translate(13px, 0px);
        position: relative;
        display: inline-block;
        text-decoration: line-through;
        color: var(--text-color-opacity);
      }
    }
  }
}

