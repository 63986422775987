
.upload-image {
    //.upload-image__loading-preview{}
    &__loading-preview{
        position: absolute;        
        border-radius: 14px;
        left: 0;
        bottom: 0;
        background-color: #00000046;
        z-index: 9;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 73px;
        height: 73px;
        top: -1px;
    }
    //.upload-image__tooltip-send{}
    &__tooltip-send{
        padding: 8px;
        left: 0 !important;
        bottom: 50px;
        min-height: 50px;
    }
    //.upload-image__form{}
    &__form {
        background: rgba(243, 239, 243, 0.3);
        padding: 10px 20px 20px;
        width: 100%;

        //.upload-image__form--full{}
        &--full {
            background: var(--gx-color-white);
        }
    }

    //.upload-image__form-title{}
    &__form-title {
        font-size: 22px;
        line-height: 40px;
        border-bottom: 2px solid #000;
        margin-bottom: 10px;
        font-weight: 500;        
    }

    //.upload-image__form-submit-wrappar{}
    &__form-submit-wrappar {
        margin-top: 25px;
        &>:first-child{
            align-self: flex-start;
            margin-top: 10px;
            font-size: 12px;
            &>label{
                font-size: 12px;
                margin-right: 25px;
                &>:first-child{
                    margin-left: 10px;
                }
                &>:last-child{
                    margin-left: 25px;
                    border: none;
                }
            }
        }
    }

    //.upload-image__form-submit-btnwrap{}
    &__form-submit-btnwrap {
        width: min-content;        
        // @include tablet-mmd {
        //     margin: 0px;
        //     justify-content: space-evenly;
        //     display: flex;
        //     min-width: 270px;
        //     flex-direction: row;
        //     width: 100%;
        // }
    }

    //.upload-image__form-submit-btndark{}
    &__form-submit-btndark {
        margin: 5px;
        width: 100%;
        overflow: hidden;
        text-transform: uppercase;
        color: var(--gx-color-white);
        font-weight: bold;
        font-size: 14px;
        background-color: var(--gx-color-primary);
        max-width: 417px;
        width: 142px;
        letter-spacing: 0px;
        min-height: 46px;
        max-height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        margin-bottom: 15px;
    }

    //.upload-image__form-submit-btn{}
    &__form-submit-btn {
        margin: 5px;
        text-transform: uppercase;
        background-color: transparent;
        color: var(--gx-color-primary);
        border: 2px solid var(--gx-color-primary);
        font-weight: bold;
        font-size: 14px;
        max-width: 417px;
        width: 142px;
        letter-spacing: 0px;
        min-height: 46px;
        max-height: 46px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    }

    //.upload-image__form-raiting{}
    &__form-raiting {
        padding: 0;
        display: flex;
        flex-wrap: nowrap;
        align-items: baseline;
        align-content: center;
        &>:first-child{
            margin-right: 5px;
            font-size: 15px;
            letter-spacing: 0px;
            line-height: 160%;
        }
    }

    //.upload-image__form-rating-indicato{}
    &__form-rating-indicato {
        padding: 0;
    }

    //.upload-image__form-upload{}
    &__form-upload {
        padding: 0;
        display: flex;
        flex-direction: column;
        position: relative;       
        z-index: 11;
        width: 100%;
        filter: blur(var(--filter-blur));
    }
    //.upload-image__form-label{}
    &__form-label{
        display: flex;
        justify-content: flex-start;
        align-items: center;

        position: absolute;
        // padding: 5px;
        pointer-events: none;
        width: 100%;
        min-width: 320px;
        font-feature-settings: 'case' on;
        font-family: "HelveticaNeue";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 128.571% */
        text-decoration-line: underline;
        &> span{
            letter-spacing: 0px;
            font-family: "HelveticaNeue";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
        }
    }
    //.upload-image__form-icon{}
    &__form-icon{
        top: -5px;
    }

    //.upload-image__form-upload-desc{}
    &__form-upload-desc {
        padding: 0;
        font-size: 15px;
        align-self: end;
        color: var(--text-color);
    }

    //.upload-image__form-upload-list{}
    &__form-upload-list {        
        display: grid;
        row-gap: 20px;
        // grid-auto-flow: column;
        grid-template-columns: repeat(auto-fit, 80px);
        justify-content: start;
        position: relative;
    }

    //.upload-image__form-upload-item{}
    &__form-upload-item {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 73px;
        height: 73px;
        border-radius: 14px;
        &:last-child {
            margin: 0;
        }
    }
    //.upload-image__form-image-container{}
    &__form-image-container{        
        position: relative;
        width: 100%;
        display: flex;
        height: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        top: 0;
        border-radius: 14px;
    }

    //.upload-image__form-upload-image{}
    &__form-upload-image {
        padding: 0;
        width: 74px;   
        height: 73px;
        border-radius: 14px;
        pointer-events: all;
    }
    
    
    
    //.upload-image__form-image-title{}
    &__form-image-title{
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        font-family: "HelveticaNeue";
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        top: 10px;
        position: relative;
        width: 70px;
        overflow-wrap:break-word;
        min-height: 26px;
    }
    //.upload-image__form-image--delete{}
    &__form-image--delete{
        padding: 8px;
        position: absolute;
        right: -12px;
        top: -11px;
        display: block;
        z-index: 10;
        pointer-events: all;
    }
    
    //.upload-image__form-image-icon--delete{}
    &__form-image-icon--delete{
        padding: 0;
        position: relative;
        z-index: 10;
    }
    








    //.upload-image__form-addprod-image{}
    &__form-addprod-image {
        padding: 0;
        border-radius: 8px;

        // margin-right: 16px;
        width: 100%;
    }
    
    //.upload-image__form-addprod-image-wrap{}
    &__form-addprod-image-wrap {
        padding: 0;
    }

    //.upload-image__form-upload-button{}
    &__form-upload-button {
        min-width: 320px;
        min-height: 48px;
        width: 100%;
        color: var(--text-color);
        font-size: 15px;
        padding: 0px 5px;
        letter-spacing: 0px;
        // border: 1px solid var(--border-color);
        border-radius: 8px;
        flex: none;
        order: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        // border: none;
        // position: relative;
        // background: transparent;
        // border-bottom: 1px dashed var(--gx-color-primary);
        // margin-bottom: 10px;
        // height: 28px;
        // max-width: 86px;
        // transform: translate(0px, 6px);

        &input:hover {
            cursor: pointer;
            color: #6c0202;
        }

    }

    //.upload-image__form-textarea{}
    &__form-textarea {
        padding: 0;
        display: flex;
        align-items: center;
        position: relative;
        width: 100%;
        height: 121px;
        font-family: var(--gx-input-font-family);
        font-weight: var(--gx-input-font-weight);
        line-height: var(--gx-line-height-normal);
        letter-spacing: var(--gx-input-letter-spacing);
        background-color: var(--gx-input-background-color);
        vertical-align: middle;
        transition: var(--gx-transition-fast) color, var(--gx-transition-fast) border, var(--gx-transition-fast) box-shadow;
        cursor: text;
        flex: 1 1 auto;
        color: var(--gx-input-color);
        border-radius: var(--gx-input-border-radius-medium);
        font-size: var(--gx-input-font-size-medium);
        box-shadow: inset 1px 1px 2px 1px #000, inset -1px -1px 2px 1px #000;
        padding: 15px;

        &::placeholder {
            user-select: none;
            color: #000;
            font-size: 16px;
            letter-spacing: 0px;
            line-height: 140%;
        }
    }

    //.
}