.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.swiper-engine {
    position: relative;
    pointer-events: none;
    --swiper-button-width: 20px;
    --swiper-button-height: 20px;
    --swiper-button-x: 0;
    --swiper-button-y: 220px;
    --swiper-arrow-button-x: 8px;
    --swiper-arrow-button-y: 222px;
    --swiper-arrow-color: red;
}